/* eslint-disable no-param-reassign */
/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

import SearchIcon from "@mui/icons-material/Search";

import FilledInput from "@mui/material/FilledInput";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import {alpha} from "@mui/material/styles";
import useTheme from "@mui/material/styles/useTheme";

import SearchHelper from "./SearchHelper.jsx";

//---------------------------------------------------------------------------
function SearchBar({
  // Props
  search,
  searchHelper,
  setSearch,
  autoFillValues,
  searchFields,
}) {
  const theme = useTheme();
  const anchorEl = React.useRef();
  const inputRef = React.useRef();

  const handleSearch = React.useCallback(
    (event) => {
      setSearch(event.target.value);
      window.scrollTo(0, 0);
    },
    [setSearch]
  );

  //---------------------------------------------------------------------------
  // Autocomplete support
  //---------------------------------------------------------------------------
  const autoCompleteSearch = React.useMemo(() => {
    const index = search.lastIndexOf(" ");
    if (index > 0) {
      return search.substring(index + 1, search.length);
    }
    return search;
  }, [search]);

  const autoCompleteMatches = React.useMemo(() => {
    if (!autoFillValues) {
      return [];
    }
    if (!autoCompleteSearch.length) {
      return [];
    }
    if (autoFillValues.some((val) => val === autoCompleteSearch)) {
      return [];
    }
    return autoFillValues.filter((val) => val.startsWith(autoCompleteSearch));
  }, [autoCompleteSearch, autoFillValues]);

  const handleAutoComplete = React.useCallback(
    (event) => {
      const index = search?.lastIndexOf(" ");
      if (index > 0) {
        setSearch(`${search.substring(0, index)} ${event.target.id}`);
      } else {
        setSearch(event.target.id);
      }
      inputRef.current.focus();
    },
    [search, setSearch]
  );

  if (!autoFillValues) {
    return null;
  }

  return (
    <Grid container alignItems="center">
      <Grid item xs>
        <FilledInput
          color="primary"
          fullWidth
          ref={anchorEl}
          inputRef={inputRef}
          sx={{
            color: theme.palette.common.white,
            backgroundColor: alpha(theme.palette.common.white, 0.15),
            margin: "5px",
            borderRadius: "10px",
            "&:hover": {
              backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
            "&.Mui-focused": {
              backgroundColor: alpha(theme.palette.common.white, 0.25),
            },
          }}
          disableUnderline
          inputProps={{id: "searchBarInput", sx: {py: "10px"}}}
          data-cy="search"
          startAdornment={
            <InputAdornment sx={{color: theme.palette.common.white}} position="start">
              <SearchIcon />
            </InputAdornment>
          }
          placeholder="Search here..."
          value={search}
          onChange={handleSearch}
          endAdornment={
            <InputAdornment sx={{color: theme.palette.common.white}} position="end">
              <SearchHelper
                anchorEl={anchorEl}
                autoFillValues={autoFillValues}
                search={search}
                searchHelper={searchHelper}
                setSearch={setSearch}
                searchFields={searchFields}
              />
            </InputAdornment>
          }
        />
        {autoFillValues?.length > 0 && (
          <Popper
            anchorEl={inputRef.current}
            open={autoCompleteMatches.length > 0}
            placement="bottom-start"
            sx={{zIndex: 1500, boxShadow: 2}}
          >
            <Paper p={0.5}>
              {autoCompleteMatches.map((value) => (
                <MenuItem key={value} id={value} onClick={handleAutoComplete}>
                  {value}
                </MenuItem>
              ))}
            </Paper>
          </Popper>
        )}
      </Grid>
    </Grid>
  );
}

SearchBar.propTypes = {
  search: PropTypes.string.isRequired,
  searchHelper: PropTypes.array,
  setSearch: PropTypes.func.isRequired,
  autoFillValues: PropTypes.array,
  searchFields: PropTypes.object,
};

export default React.memo(SearchBar);
